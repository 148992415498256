import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['address', 'city', 'state', 'country', 'error']
  static values = { apiKey: String }

  connect() {
    console.log('Connected to places controller')
    this.placeSelected = false

    this.loadGoogleMapsApi().then(() => {
      this.initAutocomplete()
    }).catch(error => {
      console.error('Failed to load the Google Maps JavaScript API:', error)
    })

    // Disable Enter key submission
    this.addressTarget.addEventListener('keydown', this.preventEnterSubmit.bind(this))
  }

  async loadGoogleMapsApi() {
    if (window.google && google.maps) {
      return Promise.resolve();
    }

    if (window.loadingGoogleMapsApi) {
      return window.loadingGoogleMapsApi;
    }

    window.loadingGoogleMapsApi = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const params = new URLSearchParams({
        key: this.apiKeyValue,
        v: 'weekly',
        libraries: 'places',
        callback: 'initGoogleMaps'
      });

      script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;
      script.async = true;
      script.defer = true;
      script.onerror = () => reject(new Error('Google Maps API failed to load'));

      window.initGoogleMaps = () => {
        resolve();
        window.initGoogleMaps = null;
      };

      document.head.appendChild(script);
    });

    return window.loadingGoogleMapsApi;
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, {
      types: ['(cities)'],
      fields: ['address_components'],
    })

    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    this.addressTarget.addEventListener('input', this.onInput.bind(this))
    this.addressTarget.addEventListener('blur', this.onBlur.bind(this))
  }

  preventEnterSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  placeChanged() {
    this.placeSelected = true
    const place = this.autocomplete.getPlace()
    const addressComponents = place.address_components

    if (addressComponents) {
      const formattedAddress = this.formatAddressComponents(addressComponents)
      this.setAddressComponents(formattedAddress)
      this.triggerAutosave()
    }
  }

  onInput() {
    this.placeSelected = false
    this.clearError()
  }

  onBlur() {
    if (!this.placeSelected && this.addressTarget.value.trim() !== '') {
      this.addressTarget.value = ''
      this.clearAddressComponents()
      this.showError('Please select a valid location from the list.')
      this.triggerAutosave()
    }
  }

  setAddressComponents(address) {
    console.log('API response:', address)
    if (this.hasStateTarget) this.stateTarget.value = address.administrative_area_level_1 || ''
    if (this.hasCityTarget) this.cityTarget.value = address.locality || ''
    if (this.hasCountryTarget) this.countryTarget.value = address.country || ''

    this.addressTarget.value = this.addressTarget.value
        .replace(/,\s[^,]*$/, '') // Removes the last comma and trailing text
        .replace(/\s*D\.C\.\s*/, '') // Removes "D.C." with optional leading/trailing spaces
        .trim(); // Strips any extra spaces from the beginning or end

    this.addressTarget.value = `${address.locality || ''}, ${address.administrative_area_level_1 || ''}, ${address.country || ''}`.trim()
  }

  clearAddressComponents() {
    if (this.hasStateTarget) this.stateTarget.value = ''
    if (this.hasCityTarget) this.cityTarget.value = ''
    if (this.hasCountryTarget) this.countryTarget.value = ''
  }

  showError(message) {
    if (this.hasErrorTarget) {
      this.errorTarget.textContent = message
      this.errorTarget.classList.remove('d-none')
      this.addressTarget.classList.add('is-invalid')
    }
  }

  clearError() {
    if (this.hasErrorTarget) {
      this.errorTarget.textContent = ''
      this.errorTarget.classList.add('d-none')
      this.addressTarget.classList.remove('is-invalid')
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {}
    addressComponents.forEach((component) => {
      const type = component.types[0]
      data[type] = component.short_name
    })
    return data
  }

  triggerAutosave() {
    const event = new CustomEvent('autosave', {
      bubbles: true,
      detail: {
        address: this.addressTarget.value,
        city: this.cityTarget ? this.cityTarget.value : '',
        state: this.stateTarget ? this.stateTarget.value : '',
        country: this.countryTarget ? this.countryTarget.value : '',
      },
    })
    this.element.dispatchEvent(event)
  }
}
